$(function () {

    // アットスチールの場合(カテゴリ14)
    var api = 'https://tiger-rack.co.jp//wp-json/wp/v2/posts?&categories=14&per_page=9&_embed';

    $.getJSON(api, function (results) {
        $.each(results, function (i, item) {

            //各項目を変数へ格納
            var id = item.id; //日付を整形
            var date_data = new Date(item.date); //日付を整形
            var year = date_data.getFullYear(); //日付を整形（年）
            var month = date_data.getMonth() + 1; //日付を整形（月）
            var day = date_data.getDate(); //日付を整形（日）
            var title = item.title.rendered; //記事タイトル
            var link = item.link; //記事のリンク
            var tags = item.tags; //タグNo
            var date = year + '年' + month + '月' + day + '日'; //投稿日
            //   var thum = item._embedded["wp:featuredmedia"][0].media_details.sizes.medium.source_url;  //アイキャッチ
            var thum = item._embedded["wp:featuredmedia"][0].source_url; //アイキャッチ


            // タイトル変数
            var title_bf = title;
            var title_af = "";

            // タグがある場合、取得したタグNoからタグ名を取得
            if (tags) {

                // タグNo.取得
                var tag_num = Number(tags);

                // タグNo.からタグ名を検索
                switch (tag_num) {
                    case 25:
                        title_af = "スチールラック";
                        break;
                    case 26:
                        title_af = "スチールラック120kg";
                        break;
                    case 27:
                        title_af = "スチールラック150kg";
                        break;
                    case 28:
                        title_af = "スチールラック200kg";
                        break;
                    case 29:
                        title_af = "スチールラック300kg";
                        break;
                    case 30:
                        title_af = "スチールラック500kg";
                        break;
                    case 31:
                        title_af = "ネスラック";
                        break;
                    case 32:
                        title_af = "パレットラック";
                        break;
                    case 33:
                        title_af = "移動棚";
                        break;
                    case 34:
                        title_af = "特殊ラック";
                        break;
                    case 35:
                        title_af = "ステンレスラック";
                        break;

                    default:
                        title_af = "";
                }

                // 拡大モーダルの設定
                var target_num = "target__" + id;

                // 画像縦横の判定
                var path = thum;
                var element = new Image();
                var image_width;
                var image_height;

                element.onload = function () {

                    image_width = Number(element.naturalWidth);
                    image_height = Number(element.naturalHeight);

                    // 横画像の時
                    if (image_width > image_height) {

                      $('.modal-content-num' + i).addClass("modal-open-width");

                    // 横画像の時
                    } else if (image_width < image_height) {

                      $('.modal-content-num' + i).addClass("modal-open-height");

                    } else {

                      $('.modal-content-num' + i).addClass("modal-open-height");

                    }
                }
                element.src = path;

                // HTML生成
                $('.wp-post').append("<li><a href='javascript:;' class='js-modal__open modal__open' data-target=" + target_num + "><img src='" + thum + "' class='ex-list-img' alt='導入事例' style='object-fit: cover;'><p class='content-title'>" + title_bf + "<br>" + title_af + "</p></a></li>");
                // HTML生成(モーダル)
                $('.wp-post-modal').append("<div id='" + target_num + "'" + "class='modal js-modal' style='display: none;'>" + "<div class='modal-bg js-modal__close'></div><div class='modal-content modal-content-num" + i + "'><img src=" + thum + "><a class='js-modal__close modal-close__text btn-close'></a></div></div>");

            }
        });
    });
});
